<template>
  <div>
    <platform-modal
      v-if="showModal"
      :value="showModal"
      :title="$t('analysis.generateReportModal.configureReportTitle')"
      icon="document-edit"
      :scrollable="false"
      :width="700"
      :content-card-padded="false"
      :saving="pendingSave"
      :save-disabled="!formData.reportChanged && !formData.reportGroupChanged"
      @save="submit"
      @input="handleShowModalInput"
    >
      <div class="report-config-modal d-flex flex-column">
        <v-tabs
          color="primary"
          class="px-3 shrink"
          v-model="selectedTab"
        >
          <v-tab
            v-for="t in availableTabs"
            :key="t.tab"
          >
            {{ t.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" class="grow pt-3">
          <v-tab-item
            v-for="t in availableTabs"
            :key="t.tab"
          >
            <div class="px-1 overflow-hidden">
              <div class="px-3 config-view overflow-y-auto overflow-x-hidden overflow-scroll-stable-both">
                <component
                  v-if="!t.formField || formData[t.formField]"
                  :is="t.component"
                  :key="t.tab"
                  v-model="formData[t.formField]"
                  v-bind="{}"
                  @input="handleInput"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </platform-modal>
    <confirmation-modal
      v-if="confirmingDiscardChanges"
      action="discard"
      entity-type="report"
      :entity="selectedReport"
      :image="false"
      :force-title="discardConfirmationModalTitle"
      :message="discardConfirmationModalMessage"
      @confirm="confirmDiscardUnsavedChanges"
      @cancel="cancelDiscardUnsavedChanges"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { clone, merge } from 'lodash'
import { UpdateReportGroupRequestBody } from '@betterboards/shared/types/Report'
import { PlatformReport, PlatformReportGroup } from '@/types/Report'
import PlatformModal from '@/components/PlatformModal.vue'
import ReportConfigForm from '@/components/forms/reports/ReportConfigForm.vue'
import ReportGroupScoresForm from '@/components/forms/reports/ReportBenchmarksForm.vue'
import { ReportBenchmark, V1ComparisonReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import ManageReportGroupsForm from '@/components/forms/reports/ManageReportGroupsForm.vue'
import { isV1ComparisonReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { DefaultReportConfig } from '@betterboards/shared/helpers/entities/Report/index'
import { ReportQuartiles } from '@betterboards/shared/types/Quartiles'

export enum ReportConfigTab {
  General,
  Scores,
  ReportGroups,
}

export default Vue.extend({
  name: 'ReportConfigModal',
  components: {
    ConfirmationModal,
    ReportConfigForm,
    PlatformModal
  },
  props: {
    targetTab: { type: Number as PropType<ReportConfigTab>, required: false }
  },
  data: () => ({
    initialized: false,
    showModal: true,
    selectedTab: ReportConfigTab.General,
    pendingSave: false,
    confirmingDiscardChanges: false,
    formData: {
      reportChanged: false,
      report: {
        id: <string | undefined>undefined,
        configuration: clone(DefaultReportConfig)
      },
      reportGroupChanged: false,
      reportGroup: {
        id: <string | undefined>undefined,
        surveyGroupId: <string | undefined>undefined,
        scores: <ReportBenchmark[] | undefined>[],
        quartiles: <ReportQuartiles[] | undefined>[]
      }
    }
  }),
  mounted () {
    this.init()
  },
  watch: {
    selectedReportGroup: 'init',
    selectedReport: 'init',
    showModal (val: boolean): void {
      if (!val) {
        this.$emit('close')
      }
    },
    targetTab (tab: ReportConfigTab): void {
      this.selectedTab = tab
    }
  },
  computed: {
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    selectedReport (): PlatformReport | null {
      return this.$store.getters['Report/selectedReport']
    },
    reportScores (): ReportBenchmark[] {
      return this.$store.getters['Report/reportBenchmarks']
    },
    reportQuartiles (): ReportQuartiles[] {
      return this.$store.getters['Report/reportQuartiles']
    },
    organisationId (): string | undefined {
      return this.$store.state.Company.selectedCompany?.parentId
    },
    hasUnsavedChanges (): boolean {
      return this.formData.reportChanged || this.formData.reportGroupChanged
    },
    availableTabs (): Array<{ tab: ReportConfigTab, title: string, formField?: 'report' | 'reportGroup', component: Vue.Component }> {
      return [
        {
          tab: ReportConfigTab.General,
          title: this.$t('modal.report.config.tabs.general') as string,
          formField: 'report',
          component: ReportConfigForm
        },
        {
          tab: ReportConfigTab.Scores,
          title: this.$t('modal.report.config.tabs.scores') as string,
          formField: 'reportGroup',
          component: ReportGroupScoresForm
        },
        {
          tab: ReportConfigTab.ReportGroups,
          title: this.$t('modal.report.config.tabs.reportGroups') as string,
          component: ManageReportGroupsForm
        }
      ]
    },
    discardConfirmationModalTitle (): string {
      return this.$t('modal.report.config.discardChangesModal.title') as string
    },
    discardConfirmationModalMessage (): string {
      return this.$t('modal.report.config.discardChangesModal.confirmationMessage') as string
    }
  },
  methods: {
    init () {
      if (this.targetTab) {
        this.selectedTab = this.targetTab
      }

      if (!this.selectedReportGroup || !this.selectedReport) {
        return
      }
      this.initialized = false

      this.formData.report.id = this.selectedReport.id
      this.formData.report.configuration = merge(clone(DefaultReportConfig), this.selectedReport.configuration)

      this.formData.reportGroup.id = this.selectedReportGroup.id
      this.formData.reportGroup.surveyGroupId = this.selectedReportGroup.surveyGroupId
      this.formData.reportGroup.scores = this.reportScores
      this.formData.reportGroup.quartiles = this.reportQuartiles

      this.initialized = true
    },
    submit () {
      if (this.formData.reportChanged) {
        this.updateReport()
      }
      if (this.formData.reportGroupChanged) {
        this.updateReportGroup()
      }
    },
    handleShowModalInput (showModal: boolean) {
      if (!showModal) {
        if (this.hasUnsavedChanges) {
          this.confirmingDiscardChanges = true
          return
        }
      }
      this.showModal = showModal
    },
    updateReport (): void {
      if (this.pendingSave) {
        return
      }
      if (!this.selectedReport || !this.formData.report.id || !this.formData.reportChanged) {
        return
      }
      this.pendingSave = true
      const payload: Partial<PlatformReport> = {
        ...this.formData.report
      }
      this.$store.dispatch('Report/saveReport', payload)
        .then(() => {
          this.showModal = false
          this.formData.reportChanged = false
        })
        .catch(() => {
          this.$toasted.error(this.$t('modal.report.config.general.saveErrorMessage') as string)
        })
        .finally(() => {
          this.pendingSave = false
        })
    },
    async updateReportGroup (): Promise<void> {
      if (this.pendingSave) {
        return
      }
      const reportGroup = this.formData.reportGroup
      if (!reportGroup?.id || !reportGroup?.surveyGroupId || !this.formData.reportGroupChanged) {
        return
      }

      let comparisonSurveyGroupId: string | undefined
      const comparisonScore: V1ComparisonReportBenchmark | undefined = reportGroup.scores?.find(isV1ComparisonReportBenchmark)
      if (comparisonScore) {
        comparisonSurveyGroupId = comparisonScore.comparisonSurveyGroupId
      }

      let quartiles: ReportQuartiles[] | undefined
      if (reportGroup.quartiles) {
        quartiles = this.handleUpdateQuartiles()
      }

      const payload: UpdateReportGroupRequestBody = {
        reportGroupId: reportGroup.id,
        surveyGroupId: reportGroup.surveyGroupId,
        scores: reportGroup.scores,
        quartiles,
        comparisonSurveyGroupId
      }
      this.pendingSave = true
      try {
        await this.$store.dispatch(
          'Report/updateReportGroup',
          payload
        )
        this.showModal = false
      } catch (err) {
        console.error('Failed to update ReportGroup:', { payload, err })
        this.$toasted.error(this.$t('modal.report.config.editScores.saveErrorMessage') as string)
        return
      } finally {
        this.pendingSave = false
      }
      this.formData.reportGroupChanged = false
    },
    handleInput (): void {
      const formField: 'report' | 'reportGroup' | undefined = this.availableTabs[this.selectedTab].formField
      switch (formField) {
        case 'report':
          this.formData.reportChanged = true
          break
        case 'reportGroup':
          this.formData.reportGroupChanged = true
          break
      }
    },
    cancelDiscardUnsavedChanges (): void {
      this.confirmingDiscardChanges = false
      this.showModal = true
    },
    confirmDiscardUnsavedChanges (): void {
      this.confirmingDiscardChanges = false
      this.showModal = false
      this.$emit('close')
    },
    handleUpdateQuartiles (): ReportQuartiles[] | undefined {
      const updatedQuartiles: ReportQuartiles[] | undefined = this.formData.reportGroup.quartiles
      const currentQuartiles: ReportQuartiles[] = [...this.reportQuartiles]
      return this.formData.reportGroup.scores?.reduce(
        (quartiles: ReportQuartiles[], score: ReportBenchmark): ReportQuartiles[] => {
          const updatedQuartile: ReportQuartiles | undefined = updatedQuartiles?.find(
            (quartile: ReportQuartiles) => quartile.benchmarkId === score.id
          )
          if (updatedQuartile) {
            quartiles.push({
              ...updatedQuartile,
              title: score.title,
              subTitle: score.subTitle
            })
            return quartiles
          }

          const existingQuartile: ReportQuartiles | undefined = currentQuartiles.find(
            (quartile: ReportQuartiles) => quartile.benchmarkId === score.id
          )
          if (existingQuartile) {
            quartiles.push({
              ...existingQuartile,
              title: score.title,
              subTitle: score.subTitle
            })
            return quartiles
          }

          return quartiles
        },
        []
      )
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-config-modal {
  min-height: 320px;
}
.config-view {
  min-height: 250px;
  max-height: 60vh;
}
</style>
