import ScaleGraph, { ScaleGraphData } from '../variants/ScaleGraph'
import ScatterGraph, { ScatterGraphData } from '../variants/ScatterGraph'
import LineGraph, { LineGraphData } from '../variants/LineGraph'
import { AbstractGraph } from '../variants/Abstract'
import GaugeGraph, { GaugeGraphData } from '../variants/GaugeGraph'

export type GraphData = ScaleGraphData | ScatterGraphData | LineGraphData | GaugeGraphData

export type GraphType = AbstractGraph<any> | ScaleGraph | ScatterGraph | LineGraph | GaugeGraph

export interface GraphInfo<DataType = GraphData> {
  variant: GraphTypes
  data: DataType
  question?: any
}

export enum DotTypes {
  Circle = 'circle',
  Rect = 'rect',
  Range = 'range',
  RangeAlt = 'range-alt',
  Dash = 'dash',
  Dotted = 'dotted',
  Line = 'line',
  Triangle = 'triangle'
}

export enum GraphColors {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface CategoryGraphDataPoint {
  x?: number // Value on X axis
  category?: string // Value on Y axis
  xVal?: number // Actual x coordinate
  yVal?: number // Actual y coordinate
  label?: string
  xOffset?: number
  alignLabel?: 'top' | 'right'
}

export interface LineConfig<Properties extends string = string> {
  property: Properties
  color: GraphColors | string
  dot: DotTypes
  dotSize?: number
  dataLabels?: boolean // defaults to true
  alignLabel?: 'top' | 'right'
  label?: string
  strokeLabel?: boolean
}

export enum GraphTypes {
  Scale = 'Scale',
  Scatter = 'Scatter',
  Line = 'Line',
  Variance = 'Variance',
  Appraisal = 'Appraisal',
  Gauge = 'Gauge',
}

export enum FontSizes {
  AxisLabel = 16,
  DataLabel = 15,
  Votes = 13,
  AxisLabelSmall = 12,
  Label = 10,
  Sublabel = 9,
}

export interface MultipleChoiceVoteResult {
  optionId: string
  value: number
}

export interface VarianceGraphResult {
  votes?: MultipleChoiceVoteResult[]
  field: string

  repeatForCriteriaId?: string | undefined
  repeatForCriteriaValue?: string | undefined
  masterBlockId?: string | undefined
  Importance?: ScaleGraphData | undefined
  Development?: ScaleGraphData | undefined
  questionText?: string | undefined
  avg?: ScaleGraphData | undefined
  self?: ScaleGraphData | undefined
}

export interface ImportanceVarianceResultData extends VarianceGraphResult {
  repeatForCriteriaId?: string
  repeatForCriteriaValue?: string
  Importance: ScaleGraphData
  Development: ScaleGraphData

  avg?: undefined
  self?: undefined
}

export interface CommitteeVarianceResultData extends VarianceGraphResult {
  questionText?: string
  avg: ScaleGraphData
  self: ScaleGraphData

  Importance?: undefined
  Development?: undefined
}

export type VarianceResultData = ImportanceVarianceResultData | CommitteeVarianceResultData
