import { GraphTypes } from './types/Graph'
import ScaleGraph from './variants/ScaleGraph'
import ScatterGraph from './variants/ScatterGraph'
import LineGraph from './variants/LineGraph'
import GaugeGraph from './variants/GaugeGraph'

export {
  ScaleGraph,
  ScatterGraph,
  LineGraph,
  GaugeGraph
}

type UnknownGraph = ScatterGraph | ScaleGraph | LineGraph | GaugeGraph

type GraphTypeMap<T = GraphTypes> = T extends GraphTypes.Scatter
  ? ScatterGraph
  : T extends GraphTypes.Scale
    ? ScaleGraph
    : T extends GraphTypes.Line
      ? LineGraph
      : T extends GraphTypes.Gauge
        ? GaugeGraph
        : undefined

export function createGraph <T extends GraphTypes> (graphType: GraphTypes, svgElement: SVGElement, config?: any): GraphTypeMap<T> {
  let graph: UnknownGraph | undefined
  switch (graphType) {
    case GraphTypes.Scale:
      graph = new ScaleGraph(svgElement, config)
      break
    case GraphTypes.Line:
    case GraphTypes.Appraisal:
      graph = new LineGraph(svgElement, config)
      break
    case GraphTypes.Scatter:
      graph = new ScatterGraph(svgElement, config)
      break
    case GraphTypes.Gauge:
      graph = new GaugeGraph(svgElement, config)
      break
    default:
      throw new Error(`Unsupported graph type "${graphType}"`)
  }
  return graph as GraphTypeMap<T>
}

export const GraphTypeIcons = {
  [GraphTypes.Scale]: 'agree-scale-graph',
  [GraphTypes.Variance]: 'variance-graph',
  [GraphTypes.Scatter]: 'scatter-graph',
  [GraphTypes.Line]: 'line-graph',
  [GraphTypes.Appraisal]: 'appraisal-graph',
  [GraphTypes.Gauge]: 'gauge-graph'
}
