import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullReportGroup = /* GraphQL */ `
  query GetFullReportGroup($id: ID!) {
    getReportGroup(id: $id) {
      id
      surveyGroupId
      companyId
      name
      variants {
        name
        variantCode
      }
      configuration {
        hideComparisonResponses
        combineComparisonResponses
        reportDocumentEstimates {
          generateCollectiveDuration
          generateGenericDuration
          generateIndividualDuration
          downloadIndividualsArchiveDuration
        }
        scores {
            showV2Universe
            allowMultipleUniverse
        }
      }
      createdAt
      updatedAt
      deletedAt
      scores
      quartiles
      reports {
        items {
          id
          companyId
          surveyGroupId
          variantCode
          comparisonSurveyGroupId
        }
        nextToken
      }
    }
  }
` as GeneratedQuery<
  APITypes.GetFullReportGroupQueryVariables,
  APITypes.GetFullReportGroupQuery
>
