/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBlock = /* GraphQL */ `mutation CreateBlock(
  $input: CreateBlockInput!
  $condition: ModelBlockConditionInput
) {
  createBlock(input: $input, condition: $condition) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlockMutationVariables,
  APITypes.CreateBlockMutation
>;
export const updateBlock = /* GraphQL */ `mutation UpdateBlock(
  $input: UpdateBlockInput!
  $condition: ModelBlockConditionInput
) {
  updateBlock(input: $input, condition: $condition) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBlockMutationVariables,
  APITypes.UpdateBlockMutation
>;
export const deleteBlock = /* GraphQL */ `mutation DeleteBlock(
  $input: DeleteBlockInput!
  $condition: ModelBlockConditionInput
) {
  deleteBlock(input: $input, condition: $condition) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBlockMutationVariables,
  APITypes.DeleteBlockMutation
>;
export const createCognitoIdentity = /* GraphQL */ `mutation CreateCognitoIdentity(
  $input: CreateCognitoIdentityInput!
  $condition: ModelCognitoIdentityConditionInput
) {
  createCognitoIdentity(input: $input, condition: $condition) {
    id
    cognitoId
    userCreatedBy
    createdAt
    updatedAt
    displayName
    email
    defaultLanguage
    avatar {
      bucket
      region
      key
      __typename
    }
    companies {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCognitoIdentityMutationVariables,
  APITypes.CreateCognitoIdentityMutation
>;
export const updateCognitoIdentity = /* GraphQL */ `mutation UpdateCognitoIdentity(
  $input: UpdateCognitoIdentityInput!
  $condition: ModelCognitoIdentityConditionInput
) {
  updateCognitoIdentity(input: $input, condition: $condition) {
    id
    cognitoId
    userCreatedBy
    createdAt
    updatedAt
    displayName
    email
    defaultLanguage
    avatar {
      bucket
      region
      key
      __typename
    }
    companies {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCognitoIdentityMutationVariables,
  APITypes.UpdateCognitoIdentityMutation
>;
export const deleteCognitoIdentity = /* GraphQL */ `mutation DeleteCognitoIdentity(
  $input: DeleteCognitoIdentityInput!
  $condition: ModelCognitoIdentityConditionInput
) {
  deleteCognitoIdentity(input: $input, condition: $condition) {
    id
    cognitoId
    userCreatedBy
    createdAt
    updatedAt
    displayName
    email
    defaultLanguage
    avatar {
      bucket
      region
      key
      __typename
    }
    companies {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCognitoIdentityMutationVariables,
  APITypes.DeleteCognitoIdentityMutation
>;
export const createCommittee = /* GraphQL */ `mutation CreateCommittee(
  $input: CreateCommitteeInput!
  $condition: ModelCommitteeConditionInput
) {
  createCommittee(input: $input, condition: $condition) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommitteeMutationVariables,
  APITypes.CreateCommitteeMutation
>;
export const updateCommittee = /* GraphQL */ `mutation UpdateCommittee(
  $input: UpdateCommitteeInput!
  $condition: ModelCommitteeConditionInput
) {
  updateCommittee(input: $input, condition: $condition) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommitteeMutationVariables,
  APITypes.UpdateCommitteeMutation
>;
export const deleteCommittee = /* GraphQL */ `mutation DeleteCommittee(
  $input: DeleteCommitteeInput!
  $condition: ModelCommitteeConditionInput
) {
  deleteCommittee(input: $input, condition: $condition) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommitteeMutationVariables,
  APITypes.DeleteCommitteeMutation
>;
export const createCommitteeIndividual = /* GraphQL */ `mutation CreateCommitteeIndividual(
  $input: CreateCommitteeIndividualInput!
  $condition: ModelCommitteeIndividualConditionInput
) {
  createCommitteeIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommitteeIndividualMutationVariables,
  APITypes.CreateCommitteeIndividualMutation
>;
export const updateCommitteeIndividual = /* GraphQL */ `mutation UpdateCommitteeIndividual(
  $input: UpdateCommitteeIndividualInput!
  $condition: ModelCommitteeIndividualConditionInput
) {
  updateCommitteeIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommitteeIndividualMutationVariables,
  APITypes.UpdateCommitteeIndividualMutation
>;
export const deleteCommitteeIndividual = /* GraphQL */ `mutation DeleteCommitteeIndividual(
  $input: DeleteCommitteeIndividualInput!
  $condition: ModelCommitteeIndividualConditionInput
) {
  deleteCommitteeIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommitteeIndividualMutationVariables,
  APITypes.DeleteCommitteeIndividualMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createCompanyIndividual = /* GraphQL */ `mutation CreateCompanyIndividual(
  $input: CreateCompanyIndividualInput!
  $condition: ModelCompanyIndividualConditionInput
) {
  createCompanyIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyIndividualMutationVariables,
  APITypes.CreateCompanyIndividualMutation
>;
export const updateCompanyIndividual = /* GraphQL */ `mutation UpdateCompanyIndividual(
  $input: UpdateCompanyIndividualInput!
  $condition: ModelCompanyIndividualConditionInput
) {
  updateCompanyIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyIndividualMutationVariables,
  APITypes.UpdateCompanyIndividualMutation
>;
export const deleteCompanyIndividual = /* GraphQL */ `mutation DeleteCompanyIndividual(
  $input: DeleteCompanyIndividualInput!
  $condition: ModelCompanyIndividualConditionInput
) {
  deleteCompanyIndividual(input: $input, condition: $condition) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyIndividualMutationVariables,
  APITypes.DeleteCompanyIndividualMutation
>;
export const createCompanyPackage = /* GraphQL */ `mutation CreateCompanyPackage(
  $input: CreateCompanyPackageInput!
  $condition: ModelCompanyPackageConditionInput
) {
  createCompanyPackage(input: $input, condition: $condition) {
    companyId
    packageId
    inherited
    public
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyPackageMutationVariables,
  APITypes.CreateCompanyPackageMutation
>;
export const updateCompanyPackage = /* GraphQL */ `mutation UpdateCompanyPackage(
  $input: UpdateCompanyPackageInput!
  $condition: ModelCompanyPackageConditionInput
) {
  updateCompanyPackage(input: $input, condition: $condition) {
    companyId
    packageId
    inherited
    public
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyPackageMutationVariables,
  APITypes.UpdateCompanyPackageMutation
>;
export const deleteCompanyPackage = /* GraphQL */ `mutation DeleteCompanyPackage(
  $input: DeleteCompanyPackageInput!
  $condition: ModelCompanyPackageConditionInput
) {
  deleteCompanyPackage(input: $input, condition: $condition) {
    companyId
    packageId
    inherited
    public
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyPackageMutationVariables,
  APITypes.DeleteCompanyPackageMutation
>;
export const createCompanyRelation = /* GraphQL */ `mutation CreateCompanyRelation(
  $input: CreateCompanyRelationInput!
  $condition: ModelCompanyRelationConditionInput
) {
  createCompanyRelation(input: $input, condition: $condition) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyRelationMutationVariables,
  APITypes.CreateCompanyRelationMutation
>;
export const updateCompanyRelation = /* GraphQL */ `mutation UpdateCompanyRelation(
  $input: UpdateCompanyRelationInput!
  $condition: ModelCompanyRelationConditionInput
) {
  updateCompanyRelation(input: $input, condition: $condition) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyRelationMutationVariables,
  APITypes.UpdateCompanyRelationMutation
>;
export const deleteCompanyRelation = /* GraphQL */ `mutation DeleteCompanyRelation(
  $input: DeleteCompanyRelationInput!
  $condition: ModelCompanyRelationConditionInput
) {
  deleteCompanyRelation(input: $input, condition: $condition) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyRelationMutationVariables,
  APITypes.DeleteCompanyRelationMutation
>;
export const createCompanyUser = /* GraphQL */ `mutation CreateCompanyUser(
  $input: CreateCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  createCompanyUser(input: $input, condition: $condition) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyUserMutationVariables,
  APITypes.CreateCompanyUserMutation
>;
export const updateCompanyUser = /* GraphQL */ `mutation UpdateCompanyUser(
  $input: UpdateCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  updateCompanyUser(input: $input, condition: $condition) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyUserMutationVariables,
  APITypes.UpdateCompanyUserMutation
>;
export const deleteCompanyUser = /* GraphQL */ `mutation DeleteCompanyUser(
  $input: DeleteCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  deleteCompanyUser(input: $input, condition: $condition) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyUserMutationVariables,
  APITypes.DeleteCompanyUserMutation
>;
export const createCompanyUserPackage = /* GraphQL */ `mutation CreateCompanyUserPackage(
  $input: CreateCompanyUserPackageInput!
  $condition: ModelCompanyUserPackageConditionInput
) {
  createCompanyUserPackage(input: $input, condition: $condition) {
    companyId
    cognitoIdentityId
    packageId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyUserPackageMutationVariables,
  APITypes.CreateCompanyUserPackageMutation
>;
export const updateCompanyUserPackage = /* GraphQL */ `mutation UpdateCompanyUserPackage(
  $input: UpdateCompanyUserPackageInput!
  $condition: ModelCompanyUserPackageConditionInput
) {
  updateCompanyUserPackage(input: $input, condition: $condition) {
    companyId
    cognitoIdentityId
    packageId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyUserPackageMutationVariables,
  APITypes.UpdateCompanyUserPackageMutation
>;
export const deleteCompanyUserPackage = /* GraphQL */ `mutation DeleteCompanyUserPackage(
  $input: DeleteCompanyUserPackageInput!
  $condition: ModelCompanyUserPackageConditionInput
) {
  deleteCompanyUserPackage(input: $input, condition: $condition) {
    companyId
    cognitoIdentityId
    packageId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyUserPackageMutationVariables,
  APITypes.DeleteCompanyUserPackageMutation
>;
export const createIndividual = /* GraphQL */ `mutation CreateIndividual(
  $input: CreateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  createIndividual(input: $input, condition: $condition) {
    id
    createdAt
    email
    companyId
    dateOfBirth
    firstName
    gender
    contactNumber
    preferredName
    familyName
    title
    role
    jobTitle
    religion
    ethnicDesignation
    joinDate
    accountStatus
    appointments {
      Chair
      SID
      NonExecutive
      CommitteeChair
      Other
      __typename
    }
    ownedCompanies {
      items {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    companies {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    teams {
      items {
        companyId
        individualId
        teamId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndividualMutationVariables,
  APITypes.CreateIndividualMutation
>;
export const updateIndividual = /* GraphQL */ `mutation UpdateIndividual(
  $input: UpdateIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  updateIndividual(input: $input, condition: $condition) {
    id
    createdAt
    email
    companyId
    dateOfBirth
    firstName
    gender
    contactNumber
    preferredName
    familyName
    title
    role
    jobTitle
    religion
    ethnicDesignation
    joinDate
    accountStatus
    appointments {
      Chair
      SID
      NonExecutive
      CommitteeChair
      Other
      __typename
    }
    ownedCompanies {
      items {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    companies {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    teams {
      items {
        companyId
        individualId
        teamId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndividualMutationVariables,
  APITypes.UpdateIndividualMutation
>;
export const deleteIndividual = /* GraphQL */ `mutation DeleteIndividual(
  $input: DeleteIndividualInput!
  $condition: ModelIndividualConditionInput
) {
  deleteIndividual(input: $input, condition: $condition) {
    id
    createdAt
    email
    companyId
    dateOfBirth
    firstName
    gender
    contactNumber
    preferredName
    familyName
    title
    role
    jobTitle
    religion
    ethnicDesignation
    joinDate
    accountStatus
    appointments {
      Chair
      SID
      NonExecutive
      CommitteeChair
      Other
      __typename
    }
    ownedCompanies {
      items {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    companies {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    teams {
      items {
        companyId
        individualId
        teamId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndividualMutationVariables,
  APITypes.DeleteIndividualMutation
>;
export const createMetric = /* GraphQL */ `mutation CreateMetric(
  $input: CreateMetricInput!
  $condition: ModelMetricConditionInput
) {
  createMetric(input: $input, condition: $condition) {
    sourceId
    action
    period
    createdAt
    updatedAt
    data
    events
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetricMutationVariables,
  APITypes.CreateMetricMutation
>;
export const updateMetric = /* GraphQL */ `mutation UpdateMetric(
  $input: UpdateMetricInput!
  $condition: ModelMetricConditionInput
) {
  updateMetric(input: $input, condition: $condition) {
    sourceId
    action
    period
    createdAt
    updatedAt
    data
    events
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetricMutationVariables,
  APITypes.UpdateMetricMutation
>;
export const createMetricEvent = /* GraphQL */ `mutation CreateMetricEvent(
  $input: CreateMetricEventInput!
  $condition: ModelMetricEventConditionInput
) {
  createMetricEvent(input: $input, condition: $condition) {
    id
    action
    companyId
    cognitoIdentityId
    createdAt
    data
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetricEventMutationVariables,
  APITypes.CreateMetricEventMutation
>;
export const createPackage = /* GraphQL */ `mutation CreatePackage(
  $input: CreatePackageInput!
  $condition: ModelPackageConditionInput
) {
  createPackage(input: $input, condition: $condition) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePackageMutationVariables,
  APITypes.CreatePackageMutation
>;
export const updatePackage = /* GraphQL */ `mutation UpdatePackage(
  $input: UpdatePackageInput!
  $condition: ModelPackageConditionInput
) {
  updatePackage(input: $input, condition: $condition) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePackageMutationVariables,
  APITypes.UpdatePackageMutation
>;
export const deletePackage = /* GraphQL */ `mutation DeletePackage(
  $input: DeletePackageInput!
  $condition: ModelPackageConditionInput
) {
  deletePackage(input: $input, condition: $condition) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePackageMutationVariables,
  APITypes.DeletePackageMutation
>;
export const createPackageItem = /* GraphQL */ `mutation CreatePackageItem(
  $input: CreatePackageItemInput!
  $condition: ModelPackageItemConditionInput
) {
  createPackageItem(input: $input, condition: $condition) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePackageItemMutationVariables,
  APITypes.CreatePackageItemMutation
>;
export const updatePackageItem = /* GraphQL */ `mutation UpdatePackageItem(
  $input: UpdatePackageItemInput!
  $condition: ModelPackageItemConditionInput
) {
  updatePackageItem(input: $input, condition: $condition) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePackageItemMutationVariables,
  APITypes.UpdatePackageItemMutation
>;
export const deletePackageItem = /* GraphQL */ `mutation DeletePackageItem(
  $input: DeletePackageItemInput!
  $condition: ModelPackageItemConditionInput
) {
  deletePackageItem(input: $input, condition: $condition) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePackageItemMutationVariables,
  APITypes.DeletePackageItemMutation
>;
export const createQuestionnaire = /* GraphQL */ `mutation CreateQuestionnaire(
  $input: CreateQuestionnaireInput!
  $condition: ModelQuestionnaireConditionInput
) {
  createQuestionnaire(input: $input, condition: $condition) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionnaireMutationVariables,
  APITypes.CreateQuestionnaireMutation
>;
export const updateQuestionnaire = /* GraphQL */ `mutation UpdateQuestionnaire(
  $input: UpdateQuestionnaireInput!
  $condition: ModelQuestionnaireConditionInput
) {
  updateQuestionnaire(input: $input, condition: $condition) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionnaireMutationVariables,
  APITypes.UpdateQuestionnaireMutation
>;
export const deleteQuestionnaire = /* GraphQL */ `mutation DeleteQuestionnaire(
  $input: DeleteQuestionnaireInput!
  $condition: ModelQuestionnaireConditionInput
) {
  deleteQuestionnaire(input: $input, condition: $condition) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionnaireMutationVariables,
  APITypes.DeleteQuestionnaireMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    companyId
    surveyGroupId
    reportGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    variantCode
    owner
    summary
    focusAreas
    configuration
    sections {
      items {
        id
        companyId
        reportId
        sectionId
        variantCode
        summary
        introduction
        results
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    documents {
      items {
        id
        reportId
        requesterId
        companyId
        targetIndividualId
        variantCode
        surveyGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        queuedAt
        deletedAt
        startedProcessingAt
        finishedAt
        failedAt
        key
        template
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    companyId
    surveyGroupId
    reportGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    variantCode
    owner
    summary
    focusAreas
    configuration
    sections {
      items {
        id
        companyId
        reportId
        sectionId
        variantCode
        summary
        introduction
        results
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    documents {
      items {
        id
        reportId
        requesterId
        companyId
        targetIndividualId
        variantCode
        surveyGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        queuedAt
        deletedAt
        startedProcessingAt
        finishedAt
        failedAt
        key
        template
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    companyId
    surveyGroupId
    reportGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    variantCode
    owner
    summary
    focusAreas
    configuration
    sections {
      items {
        id
        companyId
        reportId
        sectionId
        variantCode
        summary
        introduction
        results
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    documents {
      items {
        id
        reportId
        requesterId
        companyId
        targetIndividualId
        variantCode
        surveyGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        queuedAt
        deletedAt
        startedProcessingAt
        finishedAt
        failedAt
        key
        template
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createReportDocument = /* GraphQL */ `mutation CreateReportDocument(
  $input: CreateReportDocumentInput!
  $condition: ModelReportDocumentConditionInput
) {
  createReportDocument(input: $input, condition: $condition) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportDocumentMutationVariables,
  APITypes.CreateReportDocumentMutation
>;
export const updateReportDocument = /* GraphQL */ `mutation UpdateReportDocument(
  $input: UpdateReportDocumentInput!
  $condition: ModelReportDocumentConditionInput
) {
  updateReportDocument(input: $input, condition: $condition) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportDocumentMutationVariables,
  APITypes.UpdateReportDocumentMutation
>;
export const deleteReportDocument = /* GraphQL */ `mutation DeleteReportDocument(
  $input: DeleteReportDocumentInput!
  $condition: ModelReportDocumentConditionInput
) {
  deleteReportDocument(input: $input, condition: $condition) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportDocumentMutationVariables,
  APITypes.DeleteReportDocumentMutation
>;
export const createReportGroup = /* GraphQL */ `mutation CreateReportGroup(
  $input: CreateReportGroupInput!
  $condition: ModelReportGroupConditionInput
) {
  createReportGroup(input: $input, condition: $condition) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportGroupMutationVariables,
  APITypes.CreateReportGroupMutation
>;
export const updateReportGroup = /* GraphQL */ `mutation UpdateReportGroup(
  $input: UpdateReportGroupInput!
  $condition: ModelReportGroupConditionInput
) {
  updateReportGroup(input: $input, condition: $condition) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportGroupMutationVariables,
  APITypes.UpdateReportGroupMutation
>;
export const deleteReportGroup = /* GraphQL */ `mutation DeleteReportGroup(
  $input: DeleteReportGroupInput!
  $condition: ModelReportGroupConditionInput
) {
  deleteReportGroup(input: $input, condition: $condition) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportGroupMutationVariables,
  APITypes.DeleteReportGroupMutation
>;
export const createReportSection = /* GraphQL */ `mutation CreateReportSection(
  $input: CreateReportSectionInput!
  $condition: ModelReportSectionConditionInput
) {
  createReportSection(input: $input, condition: $condition) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportSectionMutationVariables,
  APITypes.CreateReportSectionMutation
>;
export const updateReportSection = /* GraphQL */ `mutation UpdateReportSection(
  $input: UpdateReportSectionInput!
  $condition: ModelReportSectionConditionInput
) {
  updateReportSection(input: $input, condition: $condition) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportSectionMutationVariables,
  APITypes.UpdateReportSectionMutation
>;
export const deleteReportSection = /* GraphQL */ `mutation DeleteReportSection(
  $input: DeleteReportSectionInput!
  $condition: ModelReportSectionConditionInput
) {
  deleteReportSection(input: $input, condition: $condition) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportSectionMutationVariables,
  APITypes.DeleteReportSectionMutation
>;
export const createSection = /* GraphQL */ `mutation CreateSection(
  $input: CreateSectionInput!
  $condition: ModelSectionConditionInput
) {
  createSection(input: $input, condition: $condition) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSectionMutationVariables,
  APITypes.CreateSectionMutation
>;
export const updateSection = /* GraphQL */ `mutation UpdateSection(
  $input: UpdateSectionInput!
  $condition: ModelSectionConditionInput
) {
  updateSection(input: $input, condition: $condition) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSectionMutationVariables,
  APITypes.UpdateSectionMutation
>;
export const deleteSection = /* GraphQL */ `mutation DeleteSection(
  $input: DeleteSectionInput!
  $condition: ModelSectionConditionInput
) {
  deleteSection(input: $input, condition: $condition) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSectionMutationVariables,
  APITypes.DeleteSectionMutation
>;
export const createStatistic = /* GraphQL */ `mutation CreateStatistic(
  $input: CreateStatisticInput!
  $condition: ModelStatisticConditionInput
) {
  createStatistic(input: $input, condition: $condition) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStatisticMutationVariables,
  APITypes.CreateStatisticMutation
>;
export const updateStatistic = /* GraphQL */ `mutation UpdateStatistic(
  $input: UpdateStatisticInput!
  $condition: ModelStatisticConditionInput
) {
  updateStatistic(input: $input, condition: $condition) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStatisticMutationVariables,
  APITypes.UpdateStatisticMutation
>;
export const deleteStatistic = /* GraphQL */ `mutation DeleteStatistic(
  $input: DeleteStatisticInput!
  $condition: ModelStatisticConditionInput
) {
  deleteStatistic(input: $input, condition: $condition) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStatisticMutationVariables,
  APITypes.DeleteStatisticMutation
>;
export const createStatisticTotal = /* GraphQL */ `mutation CreateStatisticTotal(
  $input: CreateStatisticTotalInput!
  $condition: ModelStatisticTotalConditionInput
) {
  createStatisticTotal(input: $input, condition: $condition) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStatisticTotalMutationVariables,
  APITypes.CreateStatisticTotalMutation
>;
export const updateStatisticTotal = /* GraphQL */ `mutation UpdateStatisticTotal(
  $input: UpdateStatisticTotalInput!
  $condition: ModelStatisticTotalConditionInput
) {
  updateStatisticTotal(input: $input, condition: $condition) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStatisticTotalMutationVariables,
  APITypes.UpdateStatisticTotalMutation
>;
export const deleteStatisticTotal = /* GraphQL */ `mutation DeleteStatisticTotal(
  $input: DeleteStatisticTotalInput!
  $condition: ModelStatisticTotalConditionInput
) {
  deleteStatisticTotal(input: $input, condition: $condition) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStatisticTotalMutationVariables,
  APITypes.DeleteStatisticTotalMutation
>;
export const createSurvey = /* GraphQL */ `mutation CreateSurvey(
  $input: CreateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  createSurvey(input: $input, condition: $condition) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyMutationVariables,
  APITypes.CreateSurveyMutation
>;
export const updateSurvey = /* GraphQL */ `mutation UpdateSurvey(
  $input: UpdateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  updateSurvey(input: $input, condition: $condition) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyMutationVariables,
  APITypes.UpdateSurveyMutation
>;
export const deleteSurvey = /* GraphQL */ `mutation DeleteSurvey(
  $input: DeleteSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  deleteSurvey(input: $input, condition: $condition) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyMutationVariables,
  APITypes.DeleteSurveyMutation
>;
export const createSurveyGroup = /* GraphQL */ `mutation CreateSurveyGroup(
  $input: CreateSurveyGroupInput!
  $condition: ModelSurveyGroupConditionInput
) {
  createSurveyGroup(input: $input, condition: $condition) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyGroupMutationVariables,
  APITypes.CreateSurveyGroupMutation
>;
export const updateSurveyGroup = /* GraphQL */ `mutation UpdateSurveyGroup(
  $input: UpdateSurveyGroupInput!
  $condition: ModelSurveyGroupConditionInput
) {
  updateSurveyGroup(input: $input, condition: $condition) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyGroupMutationVariables,
  APITypes.UpdateSurveyGroupMutation
>;
export const deleteSurveyGroup = /* GraphQL */ `mutation DeleteSurveyGroup(
  $input: DeleteSurveyGroupInput!
  $condition: ModelSurveyGroupConditionInput
) {
  deleteSurveyGroup(input: $input, condition: $condition) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyGroupMutationVariables,
  APITypes.DeleteSurveyGroupMutation
>;
export const createTeamIndividual = /* GraphQL */ `mutation CreateTeamIndividual(
  $input: CreateTeamIndividualInput!
  $condition: ModelTeamIndividualConditionInput
) {
  createTeamIndividual(input: $input, condition: $condition) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamIndividualMutationVariables,
  APITypes.CreateTeamIndividualMutation
>;
export const updateTeamIndividual = /* GraphQL */ `mutation UpdateTeamIndividual(
  $input: UpdateTeamIndividualInput!
  $condition: ModelTeamIndividualConditionInput
) {
  updateTeamIndividual(input: $input, condition: $condition) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamIndividualMutationVariables,
  APITypes.UpdateTeamIndividualMutation
>;
export const deleteTeamIndividual = /* GraphQL */ `mutation DeleteTeamIndividual(
  $input: DeleteTeamIndividualInput!
  $condition: ModelTeamIndividualConditionInput
) {
  deleteTeamIndividual(input: $input, condition: $condition) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamIndividualMutationVariables,
  APITypes.DeleteTeamIndividualMutation
>;
