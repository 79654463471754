/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateReportDocument = /* GraphQL */ `subscription OnCreateReportDocument($companyId: ID!, $requesterId: ID) {
  onCreateReportDocument(companyId: $companyId, requesterId: $requesterId) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportDocumentSubscriptionVariables,
  APITypes.OnCreateReportDocumentSubscription
>;
export const onUpdateReportDocument = /* GraphQL */ `subscription OnUpdateReportDocument($companyId: ID!, $requesterId: ID) {
  onUpdateReportDocument(companyId: $companyId, requesterId: $requesterId) {
    id
    reportId
    requesterId
    companyId
    targetIndividualId
    variantCode
    surveyGroupId
    comparisonSurveyGroupId
    createdAt
    updatedAt
    queuedAt
    deletedAt
    startedProcessingAt
    finishedAt
    failedAt
    key
    template
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportDocumentSubscriptionVariables,
  APITypes.OnUpdateReportDocumentSubscription
>;
export const onCreateCompanyUser = /* GraphQL */ `subscription OnCreateCompanyUser($cognitoIdentityId: ID!) {
  onCreateCompanyUser(cognitoIdentityId: $cognitoIdentityId) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyUserSubscriptionVariables,
  APITypes.OnCreateCompanyUserSubscription
>;
export const onDeleteCompanyUser = /* GraphQL */ `subscription OnDeleteCompanyUser($cognitoIdentityId: ID!) {
  onDeleteCompanyUser(cognitoIdentityId: $cognitoIdentityId) {
    cognitoIdentityId
    companyId
    accountType
    user {
      id
      cognitoId
      userCreatedBy
      createdAt
      updatedAt
      displayName
      email
      defaultLanguage
      avatar {
        bucket
        region
        key
        __typename
      }
      companies {
        nextToken
        __typename
      }
      status
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyUserSubscriptionVariables,
  APITypes.OnDeleteCompanyUserSubscription
>;
export const onCreateQuestionnaire = /* GraphQL */ `subscription OnCreateQuestionnaire($companyId: ID!) {
  onCreateQuestionnaire(companyId: $companyId) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQuestionnaireSubscriptionVariables,
  APITypes.OnCreateQuestionnaireSubscription
>;
export const onUpdateQuestionnaire = /* GraphQL */ `subscription OnUpdateQuestionnaire($companyId: ID!) {
  onUpdateQuestionnaire(companyId: $companyId) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQuestionnaireSubscriptionVariables,
  APITypes.OnUpdateQuestionnaireSubscription
>;
export const onDeleteQuestionnaire = /* GraphQL */ `subscription OnDeleteQuestionnaire($companyId: ID!) {
  onDeleteQuestionnaire(companyId: $companyId) {
    id
    createdAt
    deletedAt
    companyId
    name
    status
    groups
    customContent
    variants {
      name
      variantCode
      __typename
    }
    packageId
    editDisabled
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    sections {
      items {
        id
        masterSectionId
        variantCode
        questionnaireId
        companyId
        createdAt
        name
        slug
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQuestionnaireSubscriptionVariables,
  APITypes.OnDeleteQuestionnaireSubscription
>;
export const onCreateBlock = /* GraphQL */ `subscription OnCreateBlock($filter: ModelSubscriptionBlockFilterInput) {
  onCreateBlock(filter: $filter) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBlockSubscriptionVariables,
  APITypes.OnCreateBlockSubscription
>;
export const onUpdateBlock = /* GraphQL */ `subscription OnUpdateBlock($filter: ModelSubscriptionBlockFilterInput) {
  onUpdateBlock(filter: $filter) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBlockSubscriptionVariables,
  APITypes.OnUpdateBlockSubscription
>;
export const onDeleteBlock = /* GraphQL */ `subscription OnDeleteBlock($filter: ModelSubscriptionBlockFilterInput) {
  onDeleteBlock(filter: $filter) {
    id
    variantCode
    sectionId
    groupId
    companyId
    createdAt
    variant
    data
    displayOrder
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBlockSubscriptionVariables,
  APITypes.OnDeleteBlockSubscription
>;
export const onCreateCommittee = /* GraphQL */ `subscription OnCreateCommittee($filter: ModelSubscriptionCommitteeFilterInput) {
  onCreateCommittee(filter: $filter) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommitteeSubscriptionVariables,
  APITypes.OnCreateCommitteeSubscription
>;
export const onUpdateCommittee = /* GraphQL */ `subscription OnUpdateCommittee($filter: ModelSubscriptionCommitteeFilterInput) {
  onUpdateCommittee(filter: $filter) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommitteeSubscriptionVariables,
  APITypes.OnUpdateCommitteeSubscription
>;
export const onDeleteCommittee = /* GraphQL */ `subscription OnDeleteCommittee($filter: ModelSubscriptionCommitteeFilterInput) {
  onDeleteCommittee(filter: $filter) {
    id
    companyId
    name
    tags
    individuals {
      items {
        createdAt
        companyId
        committeeId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommitteeSubscriptionVariables,
  APITypes.OnDeleteCommitteeSubscription
>;
export const onCreateCommitteeIndividual = /* GraphQL */ `subscription OnCreateCommitteeIndividual(
  $filter: ModelSubscriptionCommitteeIndividualFilterInput
) {
  onCreateCommitteeIndividual(filter: $filter) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommitteeIndividualSubscriptionVariables,
  APITypes.OnCreateCommitteeIndividualSubscription
>;
export const onUpdateCommitteeIndividual = /* GraphQL */ `subscription OnUpdateCommitteeIndividual(
  $filter: ModelSubscriptionCommitteeIndividualFilterInput
) {
  onUpdateCommitteeIndividual(filter: $filter) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommitteeIndividualSubscriptionVariables,
  APITypes.OnUpdateCommitteeIndividualSubscription
>;
export const onDeleteCommitteeIndividual = /* GraphQL */ `subscription OnDeleteCommitteeIndividual(
  $filter: ModelSubscriptionCommitteeIndividualFilterInput
) {
  onDeleteCommitteeIndividual(filter: $filter) {
    createdAt
    companyId
    committeeId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    committee {
      id
      companyId
      name
      tags
      individuals {
        nextToken
        __typename
      }
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommitteeIndividualSubscriptionVariables,
  APITypes.OnDeleteCommitteeIndividualSubscription
>;
export const onCreateCompany = /* GraphQL */ `subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onCreateCompany(filter: $filter) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanySubscriptionVariables,
  APITypes.OnCreateCompanySubscription
>;
export const onUpdateCompany = /* GraphQL */ `subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onUpdateCompany(filter: $filter) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanySubscriptionVariables,
  APITypes.OnUpdateCompanySubscription
>;
export const onDeleteCompany = /* GraphQL */ `subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
  onDeleteCompany(filter: $filter) {
    id
    owner
    parentId
    name
    createdAt
    deletedAt
    companyType
    address {
      line1
      line2
      city
      region
      postalCode
      country
      __typename
    }
    phoneNumber
    registrationNumber
    numberOfEmployees
    brandLogo
    sector
    ownershipStructure
    country
    employeeCount
    marketCap
    configuration {
      primaryColor
      secondaryColor
      questionnaire
      admin
      report
      __typename
    }
    tags
    individuals {
      items {
        createdAt
        companyId
        individualId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    committees {
      items {
        id
        companyId
        name
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        cognitoIdentityId
        companyId
        accountType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    parent {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanySubscriptionVariables,
  APITypes.OnDeleteCompanySubscription
>;
export const onCreateCompanyIndividual = /* GraphQL */ `subscription OnCreateCompanyIndividual(
  $filter: ModelSubscriptionCompanyIndividualFilterInput
) {
  onCreateCompanyIndividual(filter: $filter) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyIndividualSubscriptionVariables,
  APITypes.OnCreateCompanyIndividualSubscription
>;
export const onUpdateCompanyIndividual = /* GraphQL */ `subscription OnUpdateCompanyIndividual(
  $filter: ModelSubscriptionCompanyIndividualFilterInput
) {
  onUpdateCompanyIndividual(filter: $filter) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyIndividualSubscriptionVariables,
  APITypes.OnUpdateCompanyIndividualSubscription
>;
export const onDeleteCompanyIndividual = /* GraphQL */ `subscription OnDeleteCompanyIndividual(
  $filter: ModelSubscriptionCompanyIndividualFilterInput
) {
  onDeleteCompanyIndividual(filter: $filter) {
    createdAt
    companyId
    individualId
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyIndividualSubscriptionVariables,
  APITypes.OnDeleteCompanyIndividualSubscription
>;
export const onCreateCompanyRelation = /* GraphQL */ `subscription OnCreateCompanyRelation(
  $filter: ModelSubscriptionCompanyRelationFilterInput
) {
  onCreateCompanyRelation(filter: $filter) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyRelationSubscriptionVariables,
  APITypes.OnCreateCompanyRelationSubscription
>;
export const onUpdateCompanyRelation = /* GraphQL */ `subscription OnUpdateCompanyRelation(
  $filter: ModelSubscriptionCompanyRelationFilterInput
) {
  onUpdateCompanyRelation(filter: $filter) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyRelationSubscriptionVariables,
  APITypes.OnUpdateCompanyRelationSubscription
>;
export const onDeleteCompanyRelation = /* GraphQL */ `subscription OnDeleteCompanyRelation(
  $filter: ModelSubscriptionCompanyRelationFilterInput
) {
  onDeleteCompanyRelation(filter: $filter) {
    parentId
    childId
    relationship
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyRelationSubscriptionVariables,
  APITypes.OnDeleteCompanyRelationSubscription
>;
export const onCreatePackage = /* GraphQL */ `subscription OnCreatePackage($filter: ModelSubscriptionPackageFilterInput) {
  onCreatePackage(filter: $filter) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePackageSubscriptionVariables,
  APITypes.OnCreatePackageSubscription
>;
export const onUpdatePackage = /* GraphQL */ `subscription OnUpdatePackage($filter: ModelSubscriptionPackageFilterInput) {
  onUpdatePackage(filter: $filter) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePackageSubscriptionVariables,
  APITypes.OnUpdatePackageSubscription
>;
export const onDeletePackage = /* GraphQL */ `subscription OnDeletePackage($filter: ModelSubscriptionPackageFilterInput) {
  onDeletePackage(filter: $filter) {
    id
    categories
    name
    description
    variants
    packageItems {
      items {
        packageId
        type
        key
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePackageSubscriptionVariables,
  APITypes.OnDeletePackageSubscription
>;
export const onCreatePackageItem = /* GraphQL */ `subscription OnCreatePackageItem(
  $filter: ModelSubscriptionPackageItemFilterInput
) {
  onCreatePackageItem(filter: $filter) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePackageItemSubscriptionVariables,
  APITypes.OnCreatePackageItemSubscription
>;
export const onUpdatePackageItem = /* GraphQL */ `subscription OnUpdatePackageItem(
  $filter: ModelSubscriptionPackageItemFilterInput
) {
  onUpdatePackageItem(filter: $filter) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePackageItemSubscriptionVariables,
  APITypes.OnUpdatePackageItemSubscription
>;
export const onDeletePackageItem = /* GraphQL */ `subscription OnDeletePackageItem(
  $filter: ModelSubscriptionPackageItemFilterInput
) {
  onDeletePackageItem(filter: $filter) {
    packageId
    type
    key
    content
    package {
      id
      categories
      name
      description
      variants
      packageItems {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePackageItemSubscriptionVariables,
  APITypes.OnDeletePackageItemSubscription
>;
export const onCreateReportGroup = /* GraphQL */ `subscription OnCreateReportGroup(
  $filter: ModelSubscriptionReportGroupFilterInput
) {
  onCreateReportGroup(filter: $filter) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportGroupSubscriptionVariables,
  APITypes.OnCreateReportGroupSubscription
>;
export const onUpdateReportGroup = /* GraphQL */ `subscription OnUpdateReportGroup(
  $filter: ModelSubscriptionReportGroupFilterInput
) {
  onUpdateReportGroup(filter: $filter) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportGroupSubscriptionVariables,
  APITypes.OnUpdateReportGroupSubscription
>;
export const onDeleteReportGroup = /* GraphQL */ `subscription OnDeleteReportGroup(
  $filter: ModelSubscriptionReportGroupFilterInput
) {
  onDeleteReportGroup(filter: $filter) {
    id
    surveyGroupId
    companyId
    name
    variants {
      name
      variantCode
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    configuration {
      hideComparisonResponses
      combineComparisonResponses
      reportDocumentEstimates {
        generateCollectiveDuration
        generateGenericDuration
        generateIndividualDuration
        generateAllIndividualsDuration
        downloadIndividualsArchiveDuration
        __typename
      }
      scores {
        showV2Universe
        allowMultipleUniverse
        __typename
      }
      __typename
    }
    scores
    quartiles
    reports {
      items {
        id
        companyId
        surveyGroupId
        reportGroupId
        comparisonSurveyGroupId
        createdAt
        updatedAt
        variantCode
        owner
        summary
        focusAreas
        configuration
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportGroupSubscriptionVariables,
  APITypes.OnDeleteReportGroupSubscription
>;
export const onCreateReportSection = /* GraphQL */ `subscription OnCreateReportSection(
  $filter: ModelSubscriptionReportSectionFilterInput
) {
  onCreateReportSection(filter: $filter) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportSectionSubscriptionVariables,
  APITypes.OnCreateReportSectionSubscription
>;
export const onUpdateReportSection = /* GraphQL */ `subscription OnUpdateReportSection(
  $filter: ModelSubscriptionReportSectionFilterInput
) {
  onUpdateReportSection(filter: $filter) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportSectionSubscriptionVariables,
  APITypes.OnUpdateReportSectionSubscription
>;
export const onDeleteReportSection = /* GraphQL */ `subscription OnDeleteReportSection(
  $filter: ModelSubscriptionReportSectionFilterInput
) {
  onDeleteReportSection(filter: $filter) {
    id
    companyId
    reportId
    sectionId
    variantCode
    summary
    introduction
    subSectionIntroductions {
      slug
      value
      reviewedAt
      __typename
    }
    results
    report {
      id
      companyId
      surveyGroupId
      reportGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportSectionSubscriptionVariables,
  APITypes.OnDeleteReportSectionSubscription
>;
export const onCreateSection = /* GraphQL */ `subscription OnCreateSection($filter: ModelSubscriptionSectionFilterInput) {
  onCreateSection(filter: $filter) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSectionSubscriptionVariables,
  APITypes.OnCreateSectionSubscription
>;
export const onUpdateSection = /* GraphQL */ `subscription OnUpdateSection($filter: ModelSubscriptionSectionFilterInput) {
  onUpdateSection(filter: $filter) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSectionSubscriptionVariables,
  APITypes.OnUpdateSectionSubscription
>;
export const onDeleteSection = /* GraphQL */ `subscription OnDeleteSection($filter: ModelSubscriptionSectionFilterInput) {
  onDeleteSection(filter: $filter) {
    id
    masterSectionId
    variantCode
    questionnaireId
    companyId
    createdAt
    name
    slug
    displayOrder
    blocks {
      items {
        id
        variantCode
        sectionId
        groupId
        companyId
        createdAt
        variant
        data
        displayOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSectionSubscriptionVariables,
  APITypes.OnDeleteSectionSubscription
>;
export const onCreateStatistic = /* GraphQL */ `subscription OnCreateStatistic($filter: ModelSubscriptionStatisticFilterInput) {
  onCreateStatistic(filter: $filter) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStatisticSubscriptionVariables,
  APITypes.OnCreateStatisticSubscription
>;
export const onUpdateStatistic = /* GraphQL */ `subscription OnUpdateStatistic($filter: ModelSubscriptionStatisticFilterInput) {
  onUpdateStatistic(filter: $filter) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStatisticSubscriptionVariables,
  APITypes.OnUpdateStatisticSubscription
>;
export const onDeleteStatistic = /* GraphQL */ `subscription OnDeleteStatistic($filter: ModelSubscriptionStatisticFilterInput) {
  onDeleteStatistic(filter: $filter) {
    collection
    category
    companyId
    results {
      key
      name
      value
      __typename
    }
    company {
      id
      owner
      parentId
      name
      createdAt
      deletedAt
      companyType
      address {
        line1
        line2
        city
        region
        postalCode
        country
        __typename
      }
      phoneNumber
      registrationNumber
      numberOfEmployees
      brandLogo
      sector
      ownershipStructure
      country
      employeeCount
      marketCap
      configuration {
        primaryColor
        secondaryColor
        questionnaire
        admin
        report
        __typename
      }
      tags
      individuals {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      parent {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStatisticSubscriptionVariables,
  APITypes.OnDeleteStatisticSubscription
>;
export const onCreateStatisticTotal = /* GraphQL */ `subscription OnCreateStatisticTotal(
  $filter: ModelSubscriptionStatisticTotalFilterInput
) {
  onCreateStatisticTotal(filter: $filter) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStatisticTotalSubscriptionVariables,
  APITypes.OnCreateStatisticTotalSubscription
>;
export const onUpdateStatisticTotal = /* GraphQL */ `subscription OnUpdateStatisticTotal(
  $filter: ModelSubscriptionStatisticTotalFilterInput
) {
  onUpdateStatisticTotal(filter: $filter) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStatisticTotalSubscriptionVariables,
  APITypes.OnUpdateStatisticTotalSubscription
>;
export const onDeleteStatisticTotal = /* GraphQL */ `subscription OnDeleteStatisticTotal(
  $filter: ModelSubscriptionStatisticTotalFilterInput
) {
  onDeleteStatisticTotal(filter: $filter) {
    collection
    category
    resultCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStatisticTotalSubscriptionVariables,
  APITypes.OnDeleteStatisticTotalSubscription
>;
export const onCreateSurvey = /* GraphQL */ `subscription OnCreateSurvey(
  $filter: ModelSubscriptionSurveyFilterInput
  $owner: String
) {
  onCreateSurvey(filter: $filter, owner: $owner) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSurveySubscriptionVariables,
  APITypes.OnCreateSurveySubscription
>;
export const onUpdateSurvey = /* GraphQL */ `subscription OnUpdateSurvey(
  $filter: ModelSubscriptionSurveyFilterInput
  $owner: String
) {
  onUpdateSurvey(filter: $filter, owner: $owner) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSurveySubscriptionVariables,
  APITypes.OnUpdateSurveySubscription
>;
export const onDeleteSurvey = /* GraphQL */ `subscription OnDeleteSurvey(
  $filter: ModelSubscriptionSurveyFilterInput
  $owner: String
) {
  onDeleteSurvey(filter: $filter, owner: $owner) {
    id
    groupId
    companyId
    questionnaireId
    individualId
    owner
    key
    variantCode
    dispatchedAt
    finishedAt
    deletedAt
    totals {
      questions
      questionsCompleted
      __typename
    }
    response
    status
    tags
    invitation
    individual {
      id
      createdAt
      email
      companyId
      dateOfBirth
      firstName
      gender
      contactNumber
      preferredName
      familyName
      title
      role
      jobTitle
      religion
      ethnicDesignation
      joinDate
      accountStatus
      appointments {
        Chair
        SID
        NonExecutive
        CommitteeChair
        Other
        __typename
      }
      ownedCompanies {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      committees {
        nextToken
        __typename
      }
      teams {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    questionnaire {
      id
      createdAt
      deletedAt
      companyId
      name
      status
      groups
      customContent
      variants {
        name
        variantCode
        __typename
      }
      packageId
      editDisabled
      company {
        id
        owner
        parentId
        name
        createdAt
        deletedAt
        companyType
        phoneNumber
        registrationNumber
        numberOfEmployees
        brandLogo
        sector
        ownershipStructure
        country
        employeeCount
        marketCap
        tags
        updatedAt
        __typename
      }
      sections {
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSurveySubscriptionVariables,
  APITypes.OnDeleteSurveySubscription
>;
export const onCreateSurveyGroup = /* GraphQL */ `subscription OnCreateSurveyGroup(
  $filter: ModelSubscriptionSurveyGroupFilterInput
) {
  onCreateSurveyGroup(filter: $filter) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSurveyGroupSubscriptionVariables,
  APITypes.OnCreateSurveyGroupSubscription
>;
export const onUpdateSurveyGroup = /* GraphQL */ `subscription OnUpdateSurveyGroup(
  $filter: ModelSubscriptionSurveyGroupFilterInput
) {
  onUpdateSurveyGroup(filter: $filter) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSurveyGroupSubscriptionVariables,
  APITypes.OnUpdateSurveyGroupSubscription
>;
export const onDeleteSurveyGroup = /* GraphQL */ `subscription OnDeleteSurveyGroup(
  $filter: ModelSubscriptionSurveyGroupFilterInput
) {
  onDeleteSurveyGroup(filter: $filter) {
    id
    questionnaireId
    companyId
    packageId
    targetIndividualIds
    name
    variants {
      name
      variantCode
      __typename
    }
    status
    createdAt
    updatedAt
    releasedAt
    dispatchedAt
    deletedAt
    tags
    includeInStats
    surveys {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        response
        status
        tags
        invitation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSurveyGroupSubscriptionVariables,
  APITypes.OnDeleteSurveyGroupSubscription
>;
export const onCreateTeamIndividual = /* GraphQL */ `subscription OnCreateTeamIndividual(
  $filter: ModelSubscriptionTeamIndividualFilterInput
) {
  onCreateTeamIndividual(filter: $filter) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeamIndividualSubscriptionVariables,
  APITypes.OnCreateTeamIndividualSubscription
>;
export const onUpdateTeamIndividual = /* GraphQL */ `subscription OnUpdateTeamIndividual(
  $filter: ModelSubscriptionTeamIndividualFilterInput
) {
  onUpdateTeamIndividual(filter: $filter) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeamIndividualSubscriptionVariables,
  APITypes.OnUpdateTeamIndividualSubscription
>;
export const onDeleteTeamIndividual = /* GraphQL */ `subscription OnDeleteTeamIndividual(
  $filter: ModelSubscriptionTeamIndividualFilterInput
) {
  onDeleteTeamIndividual(filter: $filter) {
    companyId
    individualId
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeamIndividualSubscriptionVariables,
  APITypes.OnDeleteTeamIndividualSubscription
>;
