import { Report, ReportDocument, ReportDocumentEstimates, ReportGroup, ReportGroupConfiguration, ReportSection, SubSectionIntroduction } from './API'
import { InputFields, PlatformQuestionnaireVariant, PlatformTranslationOverrides, QuestionnaireVariantCode } from './Platform'
import { ReportField } from '../helpers/entities/Report/index'
import { ReportBenchmark } from './ReportBenchmark'
import { SurveyGroupRequestBody } from './Authorization'
import { ReportQuartiles } from './Quartiles'

/**
 * ReportSection configuration objects exist in either a raw or a processed state:
 *  - Raw: Object has only a slug to identify it to a Section, used by ReportConfiguration Packages in their RawReportConfiguration objects
 *          and Companies in their configuration field at `report.sections`.
 *  - Processed: When releasing we attempt to match all RawReportSectionConfiguration objects to a Questionnaire Section, if successfully
 *                matched a processed ReportSectionConfiguration object will be added to the appropriate Report configuration.
 */
export interface BaseReportSectionConfiguration {
  iconPath?: string
  iconHeight?: number
}
export interface RawReportSectionConfiguration extends BaseReportSectionConfiguration {
  slug: string
}
export interface ReportSectionConfiguration extends BaseReportSectionConfiguration {
  sectionId: string
}

export enum ReportAsset {
  CoverImage = 'cover-image',
  CompanyLogoLight = 'logo-light',
  CompanyLogoDark = 'logo-dark',
  BBLogoLight = 'bb-logo-light',
  BBLogoDark = 'bb-logo-dark',
  CoverPageCompanyLogoLight = 'cover-page-logo-light',
  CoverPageCompanyLogoDark = 'cover-page-logo-dark',
  CoverPageBBLogoLight = 'cover-page-bb-logo-light',
  CoverPageBBLogoDark = 'cover-page-bb-logo-dark'
}

// Allows providing a path for Report assets on a per-Report basis, overriding the Company configuration default.
export interface ReportAssetsMap {
  [ReportAsset.CoverImage]?: string
  [ReportAsset.CompanyLogoLight]?: string
  [ReportAsset.CompanyLogoDark]?: string
  [ReportAsset.BBLogoLight]?: string
  [ReportAsset.BBLogoDark]?: string
  [ReportAsset.CoverPageCompanyLogoLight]?: string
  [ReportAsset.CoverPageCompanyLogoDark]?: string
  [ReportAsset.CoverPageBBLogoLight]?: string
  [ReportAsset.CoverPageBBLogoDark]?: string
}

export enum ReportPageSize {
  A4 = 'A4',
  USLetter = 'USLetter'
}

export type InputFieldLimits = {
  [field in InputFields]: number
}

export interface ReportFieldOverrides extends InputFieldLimits {
  sections: {
    [id: string]: InputFieldLimits // ID may be a Section ID or a SubSection/Group ID
  }
}

export interface ReportConfiguration {
  showDiversity?: boolean
  showTenure?: boolean
  signOffEnabled?: boolean
  systemAverageTitle?: string
  sectionAverageTitle?: string
  packageCategorySystemAverages?: boolean // Should System/Platform averages be filtered by the Package's Categories?
  pages?: {
    evaluationInformation?: {
      coverPage?: string
      title?: string
      content?: string
    }
    resultsOverview?: {
      coverPage?: string
      hallmarksTitle?: string
      itemLabels?: {
        [key: string]: string
      }
    }
    agenda?: {
      pageCount?: number
    }
    overallEffectiveness?: {
      showBetterBoards?: boolean
      showUniverse?: boolean
    }
  }
  translationOverrides?: PlatformTranslationOverrides
  assets?: ReportAssetsMap
  sections?: ReportSectionConfiguration[]
  pageSize?: ReportPageSize
  maxFieldLengths?: ReportFieldOverrides // Overrides for MaxFieldLengths in the Analysis
  appendix?: {
    roles: boolean
    knowhowAreas: boolean
  }
}

export interface RawReportConfiguration extends Omit<ReportConfiguration, 'sections'> {
  sections?: RawReportSectionConfiguration[]
}

export interface PlatformReportGroupConfiguration extends Omit<ReportGroupConfiguration, '__typename' | 'reportDocumentEstimates'> {
  reportDocumentEstimates?: PlatformReportDocumentEstimates
}

export type PlatformReportDocumentEstimates = Omit<ReportDocumentEstimates, '__typename'>

export interface UniverseQuery {
  collection: string
  collectionName?: string
  category: string
  categoryName?: string
}

/** Type used for each raw universe result, later refined into UniverseResult in UniverseSelector.vue */
export interface UniverseData {
  key: string
  name: string
  value: number
  masterSectionId?: string
  subSections?: UniverseData[]
}

export interface UniverseResult {
  key: string
  label: string
  score: number

  masterSectionId?: string
  subSectionId?: string
  displayOrder?: number
}

export interface PlatformReportGroup extends Omit<ReportGroup, '__typename' | 'variants' | 'reports' | 'configuration' | 'scores' | 'quartiles'> {
  variants: PlatformQuestionnaireVariant[]
  configuration?: PlatformReportGroupConfiguration | null
  scores?: ReportBenchmark[]
  quartiles?: ReportQuartiles[]
  reports: {
    items: PlatformReport[]
  }
}

export interface PlatformReportGroupRaw extends Omit<PlatformReportGroup, 'reports'> {
  reports: {
    items: PlatformReportRaw[]
  }
}

export interface PlatformReport <C = ReportConfiguration> extends Omit<Report, 'sections' | 'configuration'> {
  variantCode?: QuestionnaireVariantCode
  sections?: {
    items: PlatformReportSection[]
  }
  configuration?: C
}

/**
 * Represents a PlatformReport with a Raw configuration object (straight from Package/Company config, pre-translation)
 */
export type PlatformReportRaw = PlatformReport<RawReportConfiguration>

export interface ReportResult {
  field?: string | undefined
  sectionId?: string
  blockId?: string | null
  questionId?: string
  criteriaId?: string
  reviewedAt?: string | null
  summary?: string | undefined
  value?: any | undefined
  values?: any[] | undefined
  groupId?: string | undefined
}

export interface ResponsesResultKeyFindings extends ReportResult {
  criteriaId: string
  questionId: string
  summary: string
  values: string[]
  groupId?: string

  reviewedAt?: null
  field?: undefined
  sectionId?: undefined
  blockId?: null
}

export interface CommitteeSummaryField extends ReportResult {
  field: ReportField.CommitteeSummary
  summary: string
  values: any[]

  reviewedAt?: null
  sectionId?: undefined
  blockId?: null
  value?: undefined
  groupId?: undefined
}

export interface ResponsesResultResponseOverride extends ReportResult {
  sectionId: string
  blockId: string
  value: any
  reviewedAt?: null

  field?: undefined
  summary?: undefined
  values?: undefined
  groupId?: undefined
}

export interface ReportSectionField extends ReportResult {
  field: 'section-intro' | 'divider-page'
  reviewedAt?: string | null
  groupId?: string

  questionId?: undefined
  sectionId?: undefined
  blockId?: undefined
  criteriaId?: undefined
  summary?: undefined
  values?: undefined
  value?: undefined
}

export type ReportSectionResult = ResponsesResultKeyFindings | ResponsesResultResponseOverride | ReportSectionField | CommitteeSummaryField

export interface PlatformReportSection extends Omit<ReportSection, 'results'> {
  reviewed?: boolean
  results: ReportSectionResult[] | string
  subSectionIntroductions?: SubSectionIntroduction[]
}

export interface PlatformReportDocument extends ReportDocument {
  variantCode: QuestionnaireVariantCode | null
}

export type PartialReportDocument = Pick<PlatformReportDocument, 'surveyGroupId' | 'companyId' | 'template' | 'requesterId'> & Pick<Partial<PlatformReportDocument>, 'reportId' | 'variantCode' | 'comparisonSurveyGroupId' | 'targetIndividualId'>

export interface ReportDocumentInfo extends PlatformReportDocument {
  timeSinceCompletion?: string
  displayFinishedAt?: string
  displayStartedProcessingAt?: string
  displayQueuedAt?: string
}

// ReportManagement events:
export interface UpdateReportGroupRequestBody extends SurveyGroupRequestBody {
  reportGroupId: string
  scores?: ReportBenchmark[] | undefined
  comparisonSurveyGroupId?: string | null
  quartiles?: ReportQuartiles[] | undefined
  companyId?: string | undefined
}

export interface UpdateReportGroupResponse {
  reports: PlatformReport[]
  reportGroup: PlatformReportGroup
}
