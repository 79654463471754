<template>
  <svg
    class="gauge-graph"
    ref="graph"
    :style="{
      maxWidth: width ? `${width}px` : undefined,
      maxHeight: height ? `${height}px` : undefined
    }"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { createGraph, GaugeGraph } from '@betterboards/graphs/index'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { mapState } from 'vuex'
import { GaugeGraphConfig, GaugeGraphData } from '@betterboards/graphs/variants/GaugeGraph'

function DefaultData () {
  return {
    graph: undefined as undefined | GaugeGraph
  }
}

export default Vue.extend({
  name: 'GaugeGraph',
  props: {
    data: {
      type: Object as PropType<GaugeGraphData>,
      required: false
    },
    comparisonData: {
      type: Object as PropType<GaugeGraphData | undefined>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<GaugeGraphConfig>>,
      required: false
    }
  },
  data: DefaultData,
  mounted (): void {
    this.renderGraph()
  },
  watch: {
    data: 'renderGraph',
    'user.defaultLanguage': 'renderGraph'
  },
  computed: {
    ...mapState('User', ['user']),
    scale (): number {
      if (this.config.scale) {
        return this.config.scale
      }
      return 1
    },
    width (): number {
      // if (this.config.legend) {
      //   return 550 * this.scale
      // }
      return 500 * this.scale
    },
    height (): number {
      // if (this.config.tickLabels) {
      //   if (this.scale < 1) {
      //     return 12 - 12 * this.scale
      //   }
      //   return 12
      // }
      // if (this.config.legend) {
      //   if (this.config.comparison) {
      //     return 40 * this.scale
      //   }
      //   return 22 * this.scale
      // }
      // if (this.comparisonData) {
      //   return 40 * this.scale
      // }
      return 300 * this.scale
    }
  },
  methods: {
    renderGraph (): any {
      console.log('config', this.config)
      const comparison = !!this.comparisonData || this.config.comparison
      const config: Partial<GaugeGraphConfig> = {
        width: this.width,
        height: this.height,
        comparison,
        ...this.config,
        margin: {
          left: 1,
          right: 1,
          top: 1,
          bottom: 1,
          ...this.config.margin
        },
        scale: 1
      }

      const data: any | undefined = this.config.legend ? undefined : { ...this.data }

      const graph = this.graph = createGraph<GraphTypes.Gauge>(GraphTypes.Gauge, this.$refs.graph as SVGElement, config)
      graph.render(data)
    }
  }
})
</script>

<style lang="scss">
.gauge-graph {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 100%;
}
</style>
